@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap");

:root {
  --swiper-theme-color: black !important;
}


body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  font-weight: lighter;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav ul li a {
  font-weight: lighter;
}
nav ul li a.active {
  font-weight: normal;
}

.brand-prefix {
  font-weight: lighter;
  text-transform: uppercase;
  color: #9c9c9a;
}

.brand-sufix {
  font-weight: lighter;
  text-transform: lowercase;
  color: black;
  font-size: 1.35em;
}

.swiper-button-prev, .swiper-button-next {
  height: 100% !important;
  top: 0 !important;
  margin-top: 0 !important;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
  right: 0 !important;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
  left: 0 !important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev, 
.swiper-button-prev, .swiper-rtl .swiper-button-next {
  padding: 0 20px;
  &:hover{
    background-color: rgb(245 245 245 / 30%);
  }
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0 !important;
  padding: 10px 0;
}

.swiper-pagination.swiper-pagination-clickable{
  &:hover{
    background-color: rgb(245 245 245 / 30%);
  }
}

.realisation-description {
  background-color: rgb(245 245 245 / 90%);
  padding: 0 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

  .swiper {
    max-height:30rem;
    height: 30rem;
    position: relative;   
    .swiper-slide{
      position: relative;
      height: 100%;
      width: auto;
      /*
      display: inline-block;
      overflow: hidden;
      margin: 0; 
      width: auto;
       */
      img{
        height:100%;
        width: auto;
      }
      } 
    }
.h-144 {
  height: 30rem;
}

.h-192 {
  height: 48rem;
}

.max-h-192 {
  max-height: 48rem;
}
.max-h-144 {
  max-height: 30rem;
}
